<template>
  <div>
    <!-- Content -->
    <!-- error handelr -->
    <b-alert
      v-height-fade.appear
      fade
      :show="showDismissibleAlert"
      @dismissed="showDismissibleAlert = false"
      variant="danger"
    >
      <h4 class="alert-heading">
        Alert
      </h4>
      <div class="alert-body" v-if="errors_back != null">
        <span>{{ errors_back }}</span>
      </div>
    </b-alert>
    <!-- Brand Information -->
    <b-row>
      <b-col md="6">
        <b-card>
          <div class="justify-content-between align-items-center text-left ">
            <h5 class="mt-1">
              {{ $t("Reports.Phone_Name") }} :
              <b-badge variant="primary">{{ reportData.phone_name }}</b-badge>
            </h5>
            <h5 class="mt-1">
              {{ $t("Reports.OS_Version") }} :
              <b-badge variant="primary">{{ reportData.os_version }}</b-badge>
            </h5>
            <h5 class="mt-1">
              {{ $t("Reports.Type") }} :
              <b-badge variant="primary">{{ reportData.type }}</b-badge>
            </h5>
            <h5 class="mt-1">
              {{ $t("Reports.Serial_Number") }} :
              <b-badge variant="primary">{{
                reportData.serial_number
              }}</b-badge>
            </h5>
            <h5 class="mt-1">
              {{ $t("Reports.Device_ID") }} :
              <b-badge variant="primary">{{ reportData.device_id }}</b-badge>
            </h5>
            <h5 class="mt-1">
              IMEI :
              <b-badge variant="primary">{{ reportData.imei }}</b-badge>
            </h5>
            <h5 class="mt-1">
              {{ $t("Reports.Score") }} :
              <b-badge variant="danger">{{ reportData.score }}%</b-badge>
            </h5>
          </div>
        </b-card>
      </b-col>
      <b-col md="6">
        <b-card>
          <div v-if="delCat" class="">
            <b-button v-if="($store.state.vendorData.is_portal || $store.state.vendorData.in_store || $store.state.vendorData.is_sell_used || $store.state.vendorData.is_sell_new)"
              block
              @click="deleteData(reportData.id)"
              variant="outline-danger"
              class=""
            >
              {{ $t("Reports.Delete") }}
            </b-button>
          </div>
          <hr />
          <div v-if="delCat" class="d-flex justify-content-around text-center">
            <div class="date-section ">
              <h6 class="text-muted font-weight-bolder">
                {{ $t("Show_Vendor.Created_at") }} :
              </h6>
              <p class="mb-0">
                {{ reportData.created_at }}
              </p>
            </div>
            <div class="date-section">
              <h6 class="text-muted font-weight-bolder">
                {{ $t("Show_Vendor.Updated_at") }} :
              </h6>
              <p class="mb-0">
                {{ reportData.updated_at }}
              </p>
            </div>
          </div>
          <div v-else class="d-flex justify-content-around text-center">
            <div class="date-section ">
              <h6 class="text-muted font-weight-bolder">
                {{ $t("Show_Vendor.Created_at") }} :
              </h6>
              <p class="mb-0">
                {{ reportData.created_at }}
              </p>
            </div>
            <div class="date-section">
              <h6 class="text-muted font-weight-bolder">
                deleted at :
              </h6>
              <p class="mb-0">
                {{ reportData.updated_at }}
              </p>
            </div>
          </div>
        </b-card>
      </b-col>
    </b-row>
    <b-card class="text-center">
      <p class="h3">{{ $t("Reports.Diagnostic_Report") }}</p>
    </b-card>
    <b-card class="text-center report-image">
      <b-img :src="reportData.image"></b-img>
    </b-card>
  </div>
</template>

<script>


import CoolLightBox from "vue-cool-lightbox";
import "vue-cool-lightbox/dist/vue-cool-lightbox.min.css";
export default {

  
  components: {
    // BSV
    CoolLightBox,
   
  },
  data() {
    return {
      id: 0,
      reportData: {},
      errors_back: [],
      showDismissibleAlert: false,
      delCat: true,
      items: [],
      fields: [
        {
          key: "name",
          label: this.$t("Reports.Name"),
        },
        {
          key: "type",
          label: this.$t("Reports.Type"),
        },
        {
          key: "is_score",
          label: this.$t("Reports.In_Score"),
        },
        {
          key: "total_score",
          label: this.$t("Reports.Total_Score"),
        },
        {
          key: "score",
          label: this.$t("Reports.Score"),
        },
      ],
    };
  },
  mounted() {
    // rquest brand data
  },
  created() {
    this.id = this.$route.params.id;
    this.fetchData();
  },
  methods: {
    fetchData() {
      axios
        .get("diagnostic/reports/" + this.id)
        .then((result) => {
          this.reportData = result.data.data;
          if (this.reportData.deleted_at != null) {
            this.delCat = false;
          } else {
            this.delCat = true;
          }
          for (let index in this.reportData.sensors) {
            this.items.push({
              name: this.reportData.sensors[index].translations.name,
              type: this.reportData.sensors[index].type,
              is_score: this.reportData.sensors[index].is_score,
              total_score: this.reportData.sensors[index].total_score,
              score: this.reportData.sensors[index].score,
            });
          }
        })
        .catch((err) => {
          this.errors_back.length = 0;
          if (err.response.data.data != null) {
            if (this.isString(err.response.data.data)) {
              this.errors_back.push({
                error: err.response.data.data,
              });
            } else {
              this.errors_back = err.response.data.data;
            }
            this.showDismissibleAlert = true;
          } else {
            this.errors_back = [];
            this.errors_back.push({
              error: "internal server error",
            });
            this.showDismissibleAlert = true;
          }
        });
    },
    // delete function to delete brand
    deleteData(id) {
      this.$bvModal
        .msgBoxConfirm("يرجى تأكيد رغبتك في حذف التقرير.", {
          title: "هل أنت متأكد؟",
          size: "sm",
          okVariant: "danger",
          okTitle: "نعم",
          cancelTitle: "لا!",
          cancelVariant: "outline-secondary",
          hideHeaderClose: false,
          centered: true,
        })
        .then((value) => {
          if (value == true) {
            axios
              .delete("diagnostic/reports/" + id)
              .then((result) => {
                this.$swal({
                  position: "center",
                  icon: "success",
                  title: "Your work has been saved",
                  showConfirmButton: false,
                  timer: 1500,
                });
                this.fetchData();
              })
              .catch((err) => {
                this.$swal({
                  position: "center",
                  icon: "error",
                  title: "Error!",
                  showConfirmButton: false,
                  timer: 1500,
                });
                this.errors_back.length = 0;
                if (err.response.data.data != null) {
                  if (this.isString(err.response.data.data)) {
                    this.errors_back.push({
                      error: err.response.data.data,
                    });
                  } else {
                    this.errors_back = err.response.data.data;
                  }
                  this.showDismissibleAlert = true;
                } else {
                  this.errors_back = [];
                  this.errors_back.push({
                    error: "internal server error",
                  });
                  this.showDismissibleAlert = true;
                }
              });
          }
        });
    },
    restoreSensor(id) {
      this.$bvModal
        .msgBoxConfirm("Please confirm that you want to restore report.", {
          title: "Are You Sure?",
          size: "sm",
          okVariant: "warning",
          okTitle: "Yes",
          cancelTitle: "No",
          cancelVariant: "outline-secondary",
          hideHeaderClose: false,
          centered: true,
        })
        .then((value) => {
          if (value == true) {
            axios
              .get(
                "diagnostic/reports/" + id + "/restore",
                this.restoreSelected
              )
              .then((result) => {
                this.$swal({
                  position: "center",
                  icon: "success",
                  title: "Your work has been saved",
                  showConfirmButton: false,
                  timer: 1500,
                });
                this.fetchData();
              })
              .catch((err) => {
                this.$swal({
                  position: "center",
                  icon: "error",
                  title: "Error!",
                  showConfirmButton: false,
                  timer: 1500,
                });
                this.errors_back.length = 0;
                if (err.response.data.data != null) {
                  if (this.isString(err.response.data.data)) {
                    this.errors_back.push({
                      error: err.response.data.data,
                    });
                  } else {
                    this.errors_back = err.response.data.data;
                  }
                  this.showDismissibleAlert = true;
                } else {
                  this.errors_back = [];
                  this.errors_back.push({
                    error: "internal server error",
                  });
                  this.showDismissibleAlert = true;
                }
              });
          }
        });
    },
    isString(value) {
      return typeof value === "string" || value instanceof String;
    },
  },
};
</script>

<style lang="scss">
@import "~@core/scss/base/pages/app-ecommerce-details.scss";
.report-image {
  overflow: scroll;
}
</style>
